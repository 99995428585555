<template>
  <div>
    <h1>Create Data Settings</h1>
    <SettingForm backPath="/settings" />
  </div>
</template>

<script>
import SettingForm from '../../components/settings/SettingForm';

export default {
  components: { SettingForm },
};
</script>
